import './App.css';
import MarketingPage from './MarketingPage';

function App() {
  return (
    <MarketingPage />
  );
}

export default App;
